<template>
  <div class="login-container">
    <Background3D />
    <div class="login-form-layout">
      <el-form style="width: 500px;" ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form"
        auto-complete="on" label-position="left">
        <div class="title-container">
          <h3 class="title">ColorX云管理平台</h3>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text" auto-complete="on" />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input v-model="loginForm.password" :type="passwordType" placeholder="请输入密码" name="password"
            auto-complete="on" @keyup.enter.native="handleLogin" />
          <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>
        <el-button :loading="loading" type="primary" style="width: 97%;
    margin-bottom: 20px;
    margin-left: 16px;" @click.native.prevent="handleLogin">登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import Background3D from '@/components/Background3D.vue';
import { guid } from "@/utils/validate";
import { cryptoAes } from "@/utils/index";
import Cookies from "js-cookie";

export default {
  name: "login",
  components: {
    Background3D
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于6位数"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        uuid: "",
        captcha: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        captcha: [{ required: true, trigger: "blur", message: "请输入验证码" }],
      },
      passwordType: "password",
      loading: false,
      showDialog: false,

      imgUrl: "",
    };
  },
  created() {
    this.loginForm.uuid = guid();
    this.imgUrl =
      process.env.VUE_APP_BASE_API + `captcha.jpg?uuid=${this.loginForm.uuid}`;
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const password = cryptoAes(this.loginForm.password);
          this.$store
            .dispatch("Login", {
              userName: this.loginForm.username.trim(),
              userPwd: password,
            })
            .then((res) => {
              Cookies.set("deviceId", "");
              this.$router.push({ path: "/" });
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    vCode() {
      const uuid = guid();
      this.loginForm.uuid = uuid;
      this.imgUrl = process.env.VUE_APP_BASE_API + `captcha.jpg?uuid=${uuid}`;
    },
  },
};
</script>

<style scoped>
.login-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-layout {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  max-width: 600px;
  padding: 46px;
  border-radius: 10px;
  box-shadow: 0px 9px 18px rgba(9, 109, 146, 0.2);
  text-align: center;
  /* 添加这一行，以居中表单内的文本 */
}

.title-container .title {
  font-size: 24px;
  color: #000;
  margin: 20px auto;
  font-weight: bold;
}

.svg-container {
  position: absolute;
  /* 添加这一行，以固定图标位置 */
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.el-input {
  width: 100%;
  height: 47px;
  padding-left: 40px;
  /* 添加这一行，以避免图标遮挡输入内容 */

  input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 12px 5px 12px 15px;
    color: #000 !important;
    height: 47px;
    caret-color: $cursor;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
      -webkit-text-fill-color: $cursor !important;
    }
  }
}

.el-form-item {
  background: transparent;
  border-radius: 5px;
  color: #454545;
  margin-bottom: 15px;
  position: relative;
  /* 添加这一行，以为图标提供定位上下文 */
  width: 100%;
}

.show-pwd {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
}

.el-button {
  width: 100%;
  padding: 10px 0;
}
</style>
