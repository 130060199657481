<template>
    <div ref="bg" class="background"></div>
</template>

<script>
import * as THREE from 'three';

export default {
    name: 'Background3D',
    mounted() {
        this.initThreeJS();
    },
    methods: {
        initThreeJS() {
            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
            const renderer = new THREE.WebGLRenderer();
            renderer.setSize(window.innerWidth, window.innerHeight);
            this.$refs.bg.appendChild(renderer.domElement);

            const particles = 10000;
            const geometry = new THREE.BufferGeometry();
            const positions = new Float32Array(particles * 3);
            const colors = new Float32Array(particles * 3);

            const color = new THREE.Color();
            const n = 1000, n2 = n / 2;

            for (let i = 0; i < positions.length; i += 3) {
                const x = Math.random() * n - n2;
                const y = Math.random() * n - n2;
                const z = Math.random() * n - n2;

                positions[i] = x;
                positions[i + 1] = y;
                positions[i + 2] = z;

                const vx = (x / n) + 0.5;
                const vy = (y / n) + 0.5;
                const vz = (z / n) + 0.5;

                color.setRGB(vx, vy, vz);

                colors[i] = color.r;
                colors[i + 1] = color.g;
                colors[i + 2] = color.b;
            }

            geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

            // Create a canvas texture for round particles
            const canvas = document.createElement('canvas');
            canvas.width = 64;
            canvas.height = 64;
            const ctx = canvas.getContext('2d');
            const gradient = ctx.createRadialGradient(32, 32, 0, 32, 32, 32);
            gradient.addColorStop(0, 'rgba(255,255,255,1)');
            gradient.addColorStop(0.2, 'rgba(255,255,255,1)');
            gradient.addColorStop(0.4, 'rgba(255,255,255,0.3)');
            gradient.addColorStop(1, 'rgba(255,255,255,0)');
            ctx.fillStyle = gradient;
            ctx.beginPath();
            ctx.arc(32, 32, 32, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();

            const texture = new THREE.CanvasTexture(canvas);

            const material = new THREE.PointsMaterial({
                size: 18, // 调整粒子大小
                vertexColors: true,
                map: texture, // 设置粒子材质的纹理为圆形
                alphaTest: 0.5, // 启用 alpha 测试
                transparent: true, // 启用透明度
            });

            const points = new THREE.Points(geometry, material);
            scene.add(points);

            camera.position.z = 500;

            const animate = () => {
                requestAnimationFrame(animate);
                points.rotation.x += 0.001;
                points.rotation.y += 0.002;
                renderer.render(scene, camera);
            };

            animate();
        }
    }
};
</script>

<style scoped>
.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}
</style>