import { render, staticRenderFns } from "./Background3D.vue?vue&type=template&id=a4fd82cc&scoped=true"
import script from "./Background3D.vue?vue&type=script&lang=js"
export * from "./Background3D.vue?vue&type=script&lang=js"
import style0 from "./Background3D.vue?vue&type=style&index=0&id=a4fd82cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4fd82cc",
  null
  
)

export default component.exports